async function beforeEach(to, from, next) {
  if (to.meta.public || _.get(window.session, 'email')) {
    return next();
  } else {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    return next({
      path: '/pages/authentication/signin/basic',
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    });
  }
}

export default beforeEach;
