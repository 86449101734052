import axios from 'axios';
import { errorMessages } from '../util/exceptions';

const instance = axios.create({
  baseURL: _.join([process.env.VUE_APP_API_URL, '/api'], '') || '/api',
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => response,
  (err) => {
    setTimeout(() => {
      const statusCode = _.get(err, 'response.status', 200);
      const method = _.get(err, 'response.method', 200);
      const data = _.get(err, 'response.data', 200);

      if (errorMessages[statusCode]) {
        const statusMsg =
          typeof errorMessages[statusCode] === 'function'
            ? errorMessages[statusCode](method, data)
            : errorMessages[statusCode];

        toaster.error(statusMsg);
      }
    });

    return Promise.reject(err);
  }
);

export default instance;
