import Swal from 'sweetalert2';

export const confirmer = Swal.mixin({
  icon: 'warning',
  customClass: {
    confirmButton: 'btn btn-success',
    cancelButton: 'btn btn-danger',
  },
});

export const errorToaster = Swal.mixin({
  icon: 'error',
  position: 'top-start',
  toast: true,
  timer: 4000,
  showConfirmButton: false,
});

export const successToaster = Swal.mixin({
  icon: 'success',
  position: 'top-start',
  toast: true,
  timer: 4000,
  showConfirmButton: false,
});

export const multiErrorToaster = Swal.mixin({
  html: 'Please check these <b>errors</b> below <ul style="text-align: left; color:crimson;">%s</ul>',
});

// Extend to be similar to logger
export const error = (text) => {
  errorToaster.fire({ text });
};

export const success = (text) => {
  successToaster.fire({ text });
};

export default {
  successToaster,
  errorToaster,
  multiErrorToaster,
  confirmer,

  error,
  success,
};
