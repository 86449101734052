const defaultMessage =
  'Sorry, there were some issues retrieving your data, please try again. If the problem persists, please let us know.';

const defaultHandler = (_method, data) => {
  const defaultMessage = 'There seems to be something wrong with the input';
  const message =
    typeof data === 'string' ? data : _.get(data, 'message', defaultMessage);
  return message;
};

export const errorMessages = {
  0: `No Server Connection.
      Seems like you have lost connection to the server, try refreshing the page.
      If the problem persists feel free to send us an email, in case the problem is
      with our servers we are likely already working on it`,

  400: defaultHandler,
  403: defaultHandler,
  406: defaultHandler,
  401: defaultHandler,

  404: (method) => {
    const resourceMethods = ['patch', 'put', 'delete'];
    if (resourceMethods.includes(method)) {
      const action = method === 'delete' ? 'delete' : 'update';
      return `Sorry, the resource you are trying to ${action} doesn't exist.`;
    }
    return 'Sorry, the page you were looking for doesn’t exist.';
  },

  422: defaultMessage,
  500: defaultMessage,
};
