/*
=========================================================
* Vuetify Quiqlist - v1.0.0
=========================================================

* Product Page:  https://store.vuetifyjs.com/products/vuetify-soft-ui-dashboard-pro
* Copyright 2021 Quiqlist (https://www.auto.hammoq.com)

Coded by www.auto.hammoq.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import * as _ from 'lodash';
import moment from 'moment';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import axios from './plugins/axios';
import toaster from './plugins/toaster';
import DashboardPlugin from './plugins/dashboard-plugin';

Vue.config.productionTip = false;
Vue.prototype.lodash = _;
Vue.prototype.moment = moment;
window._ = _;
window.toaster = toaster;
window.axios = axios;

// Photoswipe Gallery
import Photoswipe from 'vue-pswipe';
Vue.use(Photoswipe);

// plugin setup
Vue.use(DashboardPlugin);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
